import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Heading, Accordion, AccordionPanel } from "grommet";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TourLabel, DeprecatedTourLabels, Filter, FilterGroup } from "../../types";
import { Checkbox } from "../formComponents/checkbox";
export const CalendarSidebar = inject('dataStore')(observer((props) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const [activeIndexes, setActiveIndex] = React.useState([0, 1]);
    const { dataStore } = props;
    const toggleAccordion = (indexes) => {
        setActiveIndex(indexes);
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.saveWebSetting('activeTourFilters', indexes);
    };
    React.useEffect(() => {
        var _a;
        const activeTourFilters = (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.webSettings) === null || _a === void 0 ? void 0 : _a.activeTourFilters;
        setActiveIndex(activeTourFilters || [0, 1]);
    }, [(_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.webSettings) === null || _a === void 0 ? void 0 : _a.activeTourFilters]);
    return (_jsxs(Box, Object.assign({ fill: "vertical", pad: "medium", background: "light-1", className: "sidebar", overflow: { vertical: "auto" }, width: { min: "200px", max: "200px" }, align: "start" }, { children: [_jsx(Heading, Object.assign({ level: "5", margin: { vertical: "small" } }, { children: t('filters') })), _jsx(Checkbox, { checked: (_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)) !== null && _b !== void 0 ? _b : false, label: t("display_weekend"), id: "weekend", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Other, Filter.Weekend) }, "weekend"), _jsx(Checkbox, { checked: (_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOwnToursFilter) !== null && _c !== void 0 ? _c : false, label: t("display_own_tours"), id: "owntours", name: "label", onChange: () => {
                    (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOwnToursFilter) ? dataStore.toggleOwnToursFilter(false) : dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleOwnToursFilter(true);
                } }, "owntours"), _jsx(Checkbox, { checked: (_d = dataStore === null || dataStore === void 0 ? void 0 : dataStore.hideInvoicedFilter) !== null && _d !== void 0 ? _d : false, label: t("hide_invoiced"), id: "invoiced", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleHideInvoicedFilter }, "invoiced"), _jsx(Checkbox, { checked: (_e = dataStore === null || dataStore === void 0 ? void 0 : dataStore.notesFilterDesktop) !== null && _e !== void 0 ? _e : false, label: t("hide_notes"), id: "hide_notes", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleNotesFilterDesktop }, "hide_notes"), _jsxs(Accordion, Object.assign({ multiple: true, animate: true, margin: { bottom: "small", top: "none" }, width: "180px", onActive: toggleAccordion, activeIndex: activeIndexes }, { children: [_jsx(AccordionPanel, Object.assign({ label: _jsx(Heading, Object.assign({ level: "5", margin: { vertical: "small" } }, { children: t("label_plural") })) }, { children: Object.values(TourLabel).filter(Label => !DeprecatedTourLabels.includes(Label)).map((l) => {
                            var _a;
                            return l !== TourLabel.None &&
                                _jsx(Checkbox, { checked: (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Label, l)) !== null && _a !== void 0 ? _a : false, label: t(l), id: l, name: "label", type: l, onChange: () => {
                                        console.log('Toggling filter:', l);
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Label, l);
                                    } }, l);
                        }) })), _jsx(AccordionPanel, Object.assign({ label: _jsx(Heading, Object.assign({ level: "5", margin: { vertical: "small" } }, { children: t("office_plural") })) }, { children: Object.values((_f = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered) !== null && _f !== void 0 ? _f : {}).map(o => {
                            var _a;
                            return (o === null || o === void 0 ? void 0 : o.id) && (o === null || o === void 0 ? void 0 : o.name) && (_jsx(Checkbox, { checked: (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Office, o.id)) !== null && _a !== void 0 ? _a : false, label: o.name, id: o.id, name: "office", type: "checkbox", onChange: () => { var _a; return dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Office, (_a = o.id) !== null && _a !== void 0 ? _a : ""); } }, o.id));
                        }) }))] }))] })));
}));
