import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { add } from 'date-fns';
import { Anchor, Box, Button } from 'grommet';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '../formComponents/dateInput';
export const CalendarDateSelection = inject("dataStore")(observer((props) => {
    const { dataStore, dayMode } = props;
    const { t, i18n } = useTranslation();
    return (_jsxs(Box, Object.assign({ direction: "row", align: "center", justify: "center", gap: "small", alignContent: "center", className: 'calendar-date-selection' }, { children: [_jsx(Box, Object.assign({ direction: "row" }, { children: _jsx(Button, { primary: true, color: "transparent", icon: _jsx(ArrowLeft01Icon, { stroke: 'sharp' }), onClick: () => { var _a; return dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(add((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date(), dayMode ? { days: -1 } : { weeks: -1 })); }, disabled: Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) }) })), _jsx(Anchor, { style: { fontSize: "normal", fontWeight: "normal" }, label: t("today"), onClick: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(new Date()), disabled: Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) }), _jsx(Box, Object.assign({ width: "160px" }, { children: _jsx(DateInput, { selected: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate, onChange: (date) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(date), disabled: Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) }) })), _jsx(Box, { children: _jsx(Button, { primary: true, color: "transparent", icon: _jsx(ArrowRight01Icon, {}), onClick: () => { var _a; return dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(add((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date(), dayMode ? { days: 1 } : { weeks: 1 })); }, disabled: Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) }) })] })));
}));
