var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, keys, remove, set, runInAction, reaction } from 'mobx';
import client from '../feathers';
import { Team } from '../models/team';
import { isSameDay, endOfWeek, startOfWeek, getWeek } from 'date-fns';
import { DataType } from '../types';
export class TeamStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.service = client.service("team");
        this.services = {
            [DataType.User]: client.service("team-users"),
            [DataType.Vehicle]: client.service("team-vehicle"),
            [DataType.Tour]: client.service("team-tour"),
        };
        this.teams = {};
        this.registerLinkEventListener = (type) => {
            this.services[type].on("created", (data) => this.update_links(data, "created", type));
            this.services[type].on("removed", (data) => this.update_links(data, "removed", type));
        };
        this.registerEventListener = () => {
            this.service.on("created", (data) => runInAction(() => set(this.teams, data.id, Team.create(data))));
            this.service.on("updated", (data) => runInAction(() => set(this.teams, data.id, Team.create(data))));
            this.service.on("patched", (data) => runInAction(() => set(this.teams, data.id, Team.create(data))));
            this.service.on("removed", (data) => runInAction(() => {
                if (!this.teams[data.id])
                    return;
                for (const id of this.teams[data.id].tour_links)
                    this.update_links({ team_id: data.id, tour_id: id }, "removed", DataType.Tour);
                for (const id of this.teams[data.id].vehicle_links)
                    this.update_links({ team_id: data.id, vehicle_id: id }, "removed", DataType.Vehicle);
                for (const id of this.teams[data.id].user_links)
                    this.update_links({ team_id: data.id, user_id: id }, "removed", DataType.User);
                set(this.teams, data.id, undefined);
            }));
            [DataType.Tour, DataType.User, DataType.Vehicle].forEach(t => this.registerLinkEventListener(t));
            this.services.users.on("patched", (data) => this.update_links(data, "patched", DataType.User));
            this.services.users.on("updated", (data) => this.update_links(data, "updated", DataType.User));
        };
        this.loadTeams = (queryOptions, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const limit = (queryOptions && queryOptions["$limit"]) || 1000;
            const query = {
                query: Object.assign({ $limit: limit, $skip: page * limit }, queryOptions)
            };
            const data = (yield this.service.find(query));
            runInAction(() => {
                if (!page)
                    keys(this.teams).forEach((k) => remove(this.teams, k));
                Object.assign(this.teams, (data.data.reduce((res, val) => {
                    res[Number(val.id)] = Team.create(val);
                    return res;
                }, {})));
            });
            if (page * limit < data.total)
                this.loadTeams(queryOptions, page + 1);
        });
        this.update_links = (data, event, type) => {
            var _a;
            if (!data || !data.team_id)
                return;
            const target_prop_name = type === DataType.User ? "user_id" : `${type}_id`;
            if (event === "removed") {
                if (!this.teams[Number(data.team_id)])
                    return;
                this.teams[Number(data.team_id)].removeLink(type, Number(data[target_prop_name]));
                if (type === DataType.Tour && this.rootStore.dataStore.getTour(Number(data.tour_id)))
                    this.rootStore.dataStore.getTour(Number(data.tour_id)).removeTeam(Number(data.team_id));
            }
            else if (event === "created") {
                if (!this.teams[Number(data.team_id)])
                    return;
                this.teams[Number(data.team_id)].addLink(type, Number(data[target_prop_name]), data);
                if (type === DataType.Tour) {
                    (_a = this.rootStore.dataStore.getTour(Number(data.tour_id))) === null || _a === void 0 ? void 0 : _a.addTeam(Number(data.team_id));
                }
            }
            else {
                if (type !== DataType.User) {
                    console.error("Additional info for this link type does not exist!");
                    return;
                }
                if (!this.teams[Number(data.team_id)])
                    return;
                this.teams[Number(data.team_id)].setUserLinkInfo(data["user_id"], data);
            }
        };
        this.getTeam = (id) => {
            return this.teams[id];
        };
        this.getTeamsByVehicle = (id) => {
            if (!id)
                return [];
            return Object.values(this.teams).filter(t => t && t.vehicle_links.includes(id));
        };
        this.getTeamsByUser = (id) => {
            if (!id)
                return [];
            return Object.values(this.teams).filter(t => t && t.user_links.includes(id));
        };
        this.link_data = (type, team_id, data_id) => {
            const id_prop = type === DataType.User ? "user_id" : `${type}_id`;
            try {
                this.services[type].create({
                    team_id: team_id,
                    [id_prop]: data_id
                });
            }
            catch (err) {
                console.error(err);
            }
        };
        this.unlink_data = (type, team_id, data_id) => __awaiter(this, void 0, void 0, function* () {
            const id_prop = type === DataType.User ? "user_id" : `${type}_id`;
            try {
                const links = yield this.services[type].find({
                    query: {
                        team_id: team_id,
                        [id_prop]: data_id
                    }
                });
                for (const l of links.data)
                    yield this.services[type].remove(l.id);
            }
            catch (err) {
                console.error(err);
            }
        });
        makeAutoObservable(this);
        this.rootStore = rootStore;
        reaction(() => [
            this.getCurWeek,
            this.rootStore.dataStore.getActiveOffice
        ], () => {
            if (!this.rootStore.dataStore.getActiveOffice)
                return;
            this.loadTeams({
                date: {
                    $gte: startOfWeek(this.getCurDate),
                    $lt: endOfWeek(this.getCurDate)
                },
                office_id: this.rootStore.dataStore.getActiveOffice.id
            });
        });
        this.registerEventListener();
    }
    get getTeams() {
        return Object.values(this.teams);
    }
    get getCurTeams() {
        return Object.values(this.teams).filter(t => t.date && isSameDay(t.date, this.getCurDate));
    }
    get getCurOfficeId() {
        return this.rootStore.dataStore.getActiveOffice && this.rootStore.dataStore.getActiveOffice.id;
    }
    get getCurDate() {
        return this.rootStore.dataStore.getCurDate;
    }
    get getCurWeek() {
        return getWeek(this.rootStore.dataStore.getCurDate);
    }
}
