import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from '@/components/formComponents/checkbox';
import { DeprecatedTourLabels, Filter, FilterGroup, TourLabel } from '@/types';
import { Box, Button, Heading, Layer } from 'grommet';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const MobileFilterModal = inject("userStore", "dataStore")(observer((props) => {
    var _a, _b, _c, _d, _e, _f;
    const { onClose, dataStore } = props;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeIndexes, setActiveIndex] = React.useState([0, 1]);
    const toggleAccordion = (indexes) => {
        setActiveIndex(indexes);
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.saveWebSetting("activeTourFilters", indexes);
    };
    React.useEffect(() => {
        var _a;
        const activeTourFilters = (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.webSettings) === null || _a === void 0 ? void 0 : _a.activeTourFilters;
        return setActiveIndex(activeTourFilters || activeIndexes);
    }, [(_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.webSettings) === null || _a === void 0 ? void 0 : _a.activeTourFilters, activeIndexes]);
    return (_jsx(Layer, Object.assign({ style: { overflow: "scroll" }, animation: "fadeIn" }, { children: _jsxs(Box, Object.assign({ background: "white", height: "100%" }, { children: [_jsx(Box, Object.assign({ align: "end" }, { children: _jsx(Button, { onClick: onClose }) })), _jsxs(Box, Object.assign({ overflow: "auto", pad: { horizontal: "medium", vertical: "small" } }, { children: [_jsx(Heading, Object.assign({ level: "5", margin: { top: "small" } }, { children: t("filters") })), _jsx(Checkbox, { checked: (_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)) !== null && _b !== void 0 ? _b : false, label: t("display_weekend"), id: "weekend", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Other, Filter.Weekend) }, "weekend"), _jsx(Checkbox, { checked: (_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOwnToursFilter) !== null && _c !== void 0 ? _c : false, label: t("display_own_tours"), id: "owntours", name: "label", onChange: () => {
                                (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOwnToursFilter)
                                    ? dataStore.toggleOwnToursFilter(false)
                                    : dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleOwnToursFilter(true);
                            } }, "owntours"), _jsx(Checkbox, { checked: (_d = dataStore === null || dataStore === void 0 ? void 0 : dataStore.hideInvoicedFilter) !== null && _d !== void 0 ? _d : false, label: t("hide_invoiced"), id: "invoiced", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleHideInvoicedFilter }, "invoiced"), _jsx(Checkbox, { checked: (_e = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getNotesFilter) !== null && _e !== void 0 ? _e : false, label: t("hide_notes"), id: "notes", name: "label", onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleNotesFilterMobile }, "notes"), _jsx(Heading, Object.assign({ level: "6", margin: { vertical: "small" } }, { children: t("label_plural") })), Object.values(TourLabel)
                            .filter((Label) => !DeprecatedTourLabels.includes(Label))
                            .map((l) => {
                            var _a;
                            return l !== TourLabel.None && (_jsx(Checkbox, { checked: (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Label, l)) !== null && _a !== void 0 ? _a : false, label: t(l), id: l, name: "label", type: l, onChange: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Label, l) }, l));
                        }), _jsx(Heading, Object.assign({ level: "6", margin: { vertical: "small" } }, { children: t("office_plural") })), Object.values((_f = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered) !== null && _f !== void 0 ? _f : {}).map(o => {
                            var _a;
                            return (o === null || o === void 0 ? void 0 : o.id) && (o === null || o === void 0 ? void 0 : o.name) && (_jsx(Checkbox, { checked: (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Office, o.id)) !== null && _a !== void 0 ? _a : false, label: o.name, id: o.id, name: "office", type: o.name, onChange: () => { var _a; return dataStore === null || dataStore === void 0 ? void 0 : dataStore.toggleFilter(FilterGroup.Office, (_a = o.id) !== null && _a !== void 0 ? _a : ""); } }, o.id));
                        })] }))] })) })));
}));
