import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TextInput } from "grommet";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TourIcon } from "../calendar/tourIcon";
import ClipLoader from "react-spinners/ClipLoader";
export const TourIDInput = inject('dataStore', 'userStore')(observer((props) => {
    var _a;
    const { t, i18n } = useTranslation();
    const { dataStore, userStore } = props;
    const [limeID, setLimeID] = useState(((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _a === void 0 ? void 0 : _a.lime_id) || null);
    const [borderColor, setBorderColor] = useState('');
    const [fetching, setFetching] = useState(false);
    const [timer, setTimer] = useState(null);
    const isMounted = React.useRef(true);
    useEffect(() => { isMounted.current = false; }, []);
    useEffect(() => {
        if (borderColor.length) {
            setTimer(setTimeout(() => { if (isMounted.current)
                setBorderColor(''); }, 5000));
        }
    }, [borderColor]);
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);
    const render = () => {
        const { lime_id } = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) || {};
        const value = !!lime_id ? `${lime_id}` : undefined;
        const icon = fetching ?
            _jsx(ClipLoader, { color: '#053162', loading: fetching, size: 20 })
            :
                _jsx(TourIcon, { size: "large", type: value ? "linked" : "unlinked" });
        return (_jsxs(Box, { children: [_jsx(Text, Object.assign({}, props.textProps, { children: props.label })), _jsx(TextInput, Object.assign({ id: "lime-id-input", name: "limeID", placeholder: t("lime_id"), defaultValue: value, icon: icon, readOnly: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales), onChange: e => setLimeID(Number((e.target.value || ''))), onKeyDown: (event) => {
                        var _a;
                        if (event.key === "Enter")
                            (_a = document.getElementById("lime-id-input")) === null || _a === void 0 ? void 0 : _a.blur();
                    }, style: { width: '100%', borderColor: borderColor } }, props.textInputProps))] }));
    };
    return render();
}));
