import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Text } from "grommet";
import { FormComponent } from "../formComponents/formComponent";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TourProps, DataType } from "../../types";
import SunEditor from 'suneditor-react';
import { align, font, fontSize, fontColor, formatBlock, hiliteColor, horizontalRule, list, link, table, textStyle } from 'suneditor/src/plugins';
const editor_sv = require('../../../locales/editor_sv.json');
export const EditTourDesc = inject('dataStore', 'userStore')(observer((props) => {
    var _a, _b;
    const { t, i18n } = useTranslation();
    const [hideToolbar, setHideToolbar] = React.useState(true);
    const { dataStore, userStore } = props;
    return (_jsxs(Box, Object.assign({ width: "medium", gap: "small", fill: true, style: { flexGrow: 1 }, pad: { top: "medium" }, className: "editTourDesc" }, { children: [_jsx(FormComponent, { type: DataType.Tour, prop: TourProps.Customer, label: t("customer_desc"), inputCmpProps: {
                    autoFocus: true,
                    value: ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _a === void 0 ? void 0 : _a.customer) || undefined,
                    disabled: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales),
                } }), _jsxs(Box, Object.assign({ fill: true, style: { flexGrow: 1 } }, { children: [_jsx(Text, Object.assign({ weight: "bold", margin: { bottom: 'xsmall' } }, { children: t("desc") })), _jsx(SunEditor, { setOptions: {
                            plugins: [align, font, fontSize, fontColor, formatBlock, hiliteColor, horizontalRule, list, link, table, textStyle],
                            toolbarContainer: '#toolbar_container',
                            buttonList: [
                                ['fullScreen', 'undo', 'redo'],
                                ['fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'removeFormat'],
                                ['-right', ':i-More-default.more_vertical', 'align', 'horizontalRule', 'list', 'table', 'link'],
                            ],
                            resizingBar: false,
                            minHeight: "380px",
                        }, setContents: ((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _b === void 0 ? void 0 : _b.desc) || "", onChange: (richText) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Tour, TourProps.Desc, richText), hideToolbar: hideToolbar, onBlur: () => setHideToolbar(true), onFocus: () => setHideToolbar(false), autoFocus: false, lang: i18n.language === 'sv' ? editor_sv : i18n.language, disable: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales) })] }))] })));
}));
