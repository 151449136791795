import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { Tip, Box, Text, ResponsiveContext } from "grommet";
import { TourTooltip } from "./tourTooltip";
import { TourIcon } from "./tourIcon";
import { inject, observer } from "mobx-react";
import { useDrag } from "react-dnd";
import { DataType, Filter, FilterGroup } from "../../types";
import { useTranslation } from "react-i18next";
export const TourCard = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { tour, dataStore, elKey, noDnD } = props;
    const size = React.useContext(ResponsiveContext);
    const [{ isDragging }, drag] = noDnD ? [{ isDragging: undefined }, undefined] : useDrag(() => ({
        type: DataType.Tour,
        item: { item: tour, key: elKey, type: DataType.Tour },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [tour]);
    const [fontSize, setFontSize] = useState("xsmall");
    const [innerCardSize, setInnerCardSize] = useState(450);
    const innerCard = useRef(null);
    function handleResize() {
        if (!innerCard.current)
            return;
        setInnerCardSize(innerCard.current.offsetWidth);
        if (innerCard.current.offsetWidth <= 110)
            setFontSize("9px");
        else if (innerCard.current.offsetWidth < 210)
            setFontSize("10px");
        else if (innerCard.current.offsetWidth < 215)
            setFontSize("11px");
        else
            setFontSize("xsmall");
    }
    useEffect(() => {
        if (!innerCard.current)
            return;
        window.addEventListener('resize', handleResize);
        handleResize();
        () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [innerCard]);
    useEffect(() => {
        handleResize();
    }, [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)]);
    const time = tour.start_time && format(tour.start_time, 'HH:mm') || '00:00';
    const label = tour.label ? t(tour.label) : '';
    const volume = tour.volume ? `${tour.volume}m³` : '';
    const dropProps = {
        responsive: true,
        stretch: true,
    };
    const renderCard = () => {
        return (_jsx(Box, Object.assign({ ref: drag, className: `calendar-tour calendar-tourcolor-${tour.label ? tour.label : ""}`, height: { min: "50px" }, width: { max: "600px" }, direction: "row", justify: "between", align: "center", round: "xsmall", pad: "xsmall", margin: { bottom: "xsmall" }, background: tour.isDelayed ? "status-critical-light" : undefined, border: {
                color: tour.isDelayed ? "status-critical-border" : "light-3",
                size: "1px",
            }, onClick: (e) => { e.stopPropagation(); dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(tour); } }, { children: _jsxs(Box, Object.assign({ basis: "full", ref: innerCard }, { children: [_jsxs(Box, Object.assign({ direction: "row", justify: "between" }, { children: [_jsx(Text, Object.assign({ size: fontSize, weight: "bold", margin: "none" }, { children: [time, label, volume].filter(s => Boolean(s)).join(" - ") })), _jsxs(Box, Object.assign({ direction: "row", gap: "xsmall", basis: "auto" }, { children: [Boolean(tour.num_comments) &&
                                        (innerCardSize > 130 ||
                                            (innerCardSize > 100 && !tour.label)) ? (_jsx(Box, Object.assign({ round: true, pad: { horizontal: "xsmall" }, background: "brand", height: { max: "15px" }, width: { max: "15px" }, align: "center" }, { children: _jsx(Text, Object.assign({ size: "10px" }, { children: tour.num_comments > 9 ? "9+" : tour.num_comments })) }))) : '', tour.notified &&
                                        _jsx(TourIcon, { type: "notified" }), tour.label &&
                                        ((innerCardSize > 195 && tour.isDelayed) ||
                                            (innerCardSize > 170 && !tour.isDelayed) ||
                                            (innerCardSize > 170 && tour.isDelayed && Boolean(!tour.num_comments)) ||
                                            (innerCardSize > 150 && !tour.isDelayed && Boolean(!tour.num_comments)) ||
                                            (innerCardSize > 150 && tour.isDelayed && Boolean(!tour.lime_id)) ||
                                            (innerCardSize > 130 && !tour.isDelayed && Boolean(!tour.lime_id))) ? (_jsx(TourIcon, { type: tour.label })) : '', Boolean(tour.lime_id) &&
                                        ((innerCardSize > 170 && tour.isDelayed) ||
                                            (innerCardSize > 150 && !tour.isDelayed) ||
                                            (innerCardSize > 150 && tour.isDelayed && Boolean(!tour.num_comments)) ||
                                            (innerCardSize > 150 && !tour.isDelayed && Boolean(tour.num_comments)) ||
                                            (innerCardSize > 130 && !tour.isDelayed && Boolean(!tour.num_comments)) ||
                                            (innerCardSize > 130 && tour.isDelayed && !tour.label) ||
                                            (innerCardSize > 110 && !tour.isDelayed && !tour.label) ||
                                            (innerCardSize > 100 && tour.isDelayed && Boolean(!tour.num_comments) && Boolean(!tour.label)) ||
                                            (innerCardSize > 100 && !tour.isDelayed && Boolean(tour.num_comments) && Boolean(!tour.label)) ||
                                            (innerCardSize > 100 && !tour.isDelayed && Boolean(!tour.num_comments) && Boolean(!tour.label))) ? (_jsx(TourIcon, { type: "linked" })) : '', tour.isDelayed &&
                                        ((innerCardSize > 150) ||
                                            (innerCardSize > 130 && Boolean(!tour.num_comments)) ||
                                            (innerCardSize > 130 && !tour.label) ||
                                            (innerCardSize > 110 && !tour.label) ||
                                            (innerCardSize > 110 && Boolean(!tour.num_comments) && !tour.label && Boolean(tour.lime_id)) ||
                                            (innerCardSize > 100 && !tour.label)) ? (_jsx(TourIcon, { type: "delayed" })) : ''] }))] })), _jsx(Text, Object.assign({ size: "xsmall", truncate: true }, { children: tour.title }))] })) }), "tour" + elKey + tour.id));
    };
    if (isDragging)
        return renderCard();
    return (_jsx(_Fragment, { children: size === "small" ? renderCard() :
            (_jsx(Tip, Object.assign({ dropProps: dropProps, content: _jsx(TourTooltip, { tour: tour }) }, { children: renderCard() }), "tip" + elKey + tour.id)) }));
}));
