import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MobileMenu } from '@/components/mobileMenu';
import { AppRoute } from '@/routes';
import { Language, UserRole } from '@/types';
import { Anchor, Box, Button, Header, Menu, Nav, ResponsiveContext } from 'grommet';
import { Calendar01Icon, Calendar02Icon, Calendar03Icon, EarthIcon, Logout01Icon, Menu01Icon, UserIcon, Wrench01Icon, } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
export const AppBar = inject("userStore", "dataStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userStore, dataStore } = props;
    const [openMenu, setOpenMenu] = React.useState(false);
    const location = useLocation();
    const profile = (_jsx(Box, Object.assign({ align: "center", direction: "row", justify: "between", className: 'header-nav-div' }, { children: _jsxs(Nav, Object.assign({ direction: "row", gap: "none", className: 'header-nav' }, { children: [_jsx(Button, { icon: _jsx(UserIcon, { color: "white" }), onClick: () => navigate(AppRoute.Profile) }), _jsx(Button, { icon: _jsx(Logout01Icon, { color: "white" }), onClick: userStore === null || userStore === void 0 ? void 0 : userStore.logout })] })) })));
    const language = (_jsx(Box, Object.assign({ align: "center", hoverIndicator: true, className: 'header-user-nav' }, { children: _jsx(Menu, { icon: _jsx(EarthIcon, { color: "white" }), items: [
                {
                    label: "EN",
                    onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.setUserLanguage(Language.English),
                    disabled: i18n.language === "en",
                },
                {
                    label: "DE",
                    onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.setUserLanguage(Language.German),
                    disabled: i18n.language === "de",
                },
                {
                    label: "SV",
                    onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.setUserLanguage(Language.Swedish),
                    disabled: i18n.language === "sv",
                },
            ] }) })));
    const navBarRoutes = [
        {
            icon: _jsx(Calendar01Icon, { color: "white" }),
            label: t("calendar"),
            route: AppRoute.Calendar,
            onClick: () => {
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(null);
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
            },
            allowedRoles: Object.values(UserRole),
        },
        {
            icon: _jsx(Calendar03Icon, { color: "white" }),
            label: t("scheduler"),
            route: AppRoute.Scheduler,
            allowedRoles: [UserRole.Admin],
        },
        {
            icon: _jsx(Calendar02Icon, { color: "white" }),
            label: t("planner"),
            route: AppRoute.Planner,
            allowedRoles: [UserRole.Admin, UserRole.Sales, UserRole.Default],
        },
        {
            icon: _jsx(Wrench01Icon, { color: "white" }),
            label: t("resources"),
            route: AppRoute.Resources,
            allowedRoles: [UserRole.Admin],
        },
    ];
    return (_jsx(Header, Object.assign({ className: 'header', direction: "row-reverse", pad: { horizontal: "small" }, height: "4rem", align: "center", background: "brand" }, { children: _jsx(ResponsiveContext.Consumer, { children: (responsive) => {
                var _a;
                return responsive === "small" && (userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn) ? (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ direction: "row", align: "center" }, { children: [language, _jsx(Button, { icon: _jsx(Menu01Icon, { color: "white" }), onClick: () => setOpenMenu(true) })] })), openMenu && (_jsx(_Fragment, { children: _jsx(MobileMenu, { closeMenu: () => setOpenMenu(false) }) }))] })) : (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ direction: "row", alignSelf: "center", align: "center", pad: { vertical: "small", horizontal: "medium" } }, { children: [language, (userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn) && profile] })), _jsx(Nav, Object.assign({ direction: "row", gap: "xsmall" }, { children: ((userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn) && ((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.role)) &&
                                navBarRoutes.map((r) => {
                                    var _a;
                                    if (!r.allowedRoles.includes((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.role))
                                        return null;
                                    return (_jsx(Anchor, { icon: r.icon, label: r.label, onClick: () => {
                                            r.onClick && r.onClick();
                                            navigate(r.route);
                                        }, className: location.pathname === r.route
                                            ? "nav-link current"
                                            : "nav-link" }, r.route));
                                }) }))] }));
            } }) })));
}));
