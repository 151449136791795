import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-datepicker/dist/react-datepicker.css';
import { de, sv } from 'date-fns/esm/locale';
import { Calendar } from 'grommet-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
registerLocale('de', de);
registerLocale('sv', sv);
const localizedFormats = {
    "sv": "yyyy/MM/dd",
};
export const DateInput = (props) => {
    const { t, i18n } = useTranslation();
    return (_jsxs("div", Object.assign({ className: "react-datepicker-position-wrapper" }, { children: [_jsx(DatePicker, Object.assign({ className: "react-datepicker-wrapper", locale: i18n.language, dateFormat: localizedFormats[i18n.language] || undefined }, props)), _jsx(Calendar, { className: "react-datepicker-calendar-icon", size: 'small' })] })));
};
