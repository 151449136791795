import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Calendar } from '@/components/calendar/calendar';
import { CalendarSidebar } from '@/components/calendar/calendarSidebar';
import { MobileCalendar } from '@/components/calendar/mobileCalendar';
import { EditNote } from '@/components/editNote/editNote';
import { EditTour } from '@/components/editTour/editTour';
import { Box, ResponsiveContext } from 'grommet';
import React from 'react';
export const CalendarView = (props) => {
    const size = React.useContext(ResponsiveContext);
    return (_jsxs(Box, Object.assign({ fill: true, direction: "row", align: "stretch", background: "#f3f2ef" }, { children: [size === "small" ? null : _jsx(CalendarSidebar, {}), size === "small" ? _jsx(MobileCalendar, {}) : _jsx(Calendar, {}), _jsx(EditTour, {}), _jsx(EditNote, {})] })));
};
