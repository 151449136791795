var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '@/feathers';
import i18next from '@/i18n';
import { Comment, Note, Office, Tour, Vehicle } from '@/models';
import { TourUser } from '@/models/tourUser';
import { DataType, Filter, FilterGroup, TourLabel } from '@/types';
import { arrToObjAgg } from '@/util';
import { add, endOfDay, endOfWeek, format, getDay, getISODay, isSameDay, isSameWeek, startOfDay, startOfWeek, } from 'date-fns';
import { autorun, keys, makeAutoObservable, reaction, remove, runInAction, set, when } from 'mobx';
import { computedFn } from 'mobx-utils';
export class DataStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.debounceDelay = 500;
        this.debounceTimer = null;
        this.tour_service = client.service('tour');
        this.note_service = client.service('note');
        this.office_service = client.service('office');
        this.vehicle_service = client.service('vehicle');
        this.comment_service = client.service('comment');
        this.tour_users_service = client.service('tour-users');
        this.searchString = "";
        this.curDate = new Date();
        this.activeTour = null;
        this.activeNote = null;
        this.activeOffice = null;
        this.activeVehicle = null;
        this.replyComment = null;
        this.commentFile = null;
        this.ownToursFilter = false;
        this.notesFilterMobile = true;
        this.notesFilterDesktop = false;
        this.webSettings = { activeTourFilters: [0, 1] };
        this.hideInvoicedFilter = false;
        this.tours = {};
        this.tour_users = {};
        this.notes = {};
        this.offices = {};
        this.vehicles = {};
        this.temp_vehicles = {};
        this.comments = {};
        this.searchResultTours = {};
        this.searchResultNotes = {};
        this.tours_by_office = {};
        this.searchResultToursByOffice = {};
        this.isLoading = {
            tours: [],
            notes: [],
            offices: [],
            vehicles: [],
            temp_vehicles: [],
            tour_users: [],
            comments: [],
            search_notes: [],
            search_tour: [],
        };
        this.lastLoaded = {
            tours: null,
            notes: null,
            offices: null,
            vehicles: null,
            temp_vehicles: null,
            tour_users: null,
            comments: null,
            search_notes: null,
            search_tour: null,
        };
        this.registerEventListener = (service, container, conversion, temp_container = null, checkDate = false) => {
            service.on("created", (data) => {
                if (checkDate && !(data.date && isSameWeek(new Date(data.date), this.curDate)))
                    return;
                runInAction(() => set(data["temp_date"] && temp_container ? temp_container : container, data.id, conversion(data)));
            });
            service.on("updated", (data) => runInAction(() => set(data["temp_date"] && temp_container ? temp_container : container, data.id, conversion(data))));
            service.on("patched", (data) => runInAction(() => set(data["temp_date"] && temp_container ? temp_container : container, data.id, conversion(data))));
            service.on("removed", (data) => runInAction(() => remove(data["temp_date"] && temp_container ? temp_container : container, "" + data.id)));
        };
        this.loadItems = (queryOptions, service, container, loading_key, conversion, callBack, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const _DEBUG = false;
            const now = new Date().getTime();
            const limit = (queryOptions["query"] && queryOptions["query"]["$limit"]) || 1000;
            _DEBUG && console.log("Adding to", this.isLoading[loading_key], loading_key, "page", page);
            runInAction(() => this.isLoading[loading_key].push(now));
            const load_id = now;
            _DEBUG && console.log("Running Query", Object.assign({ $limit: limit, $skip: page * limit }, queryOptions));
            const data = (yield service.find({
                query: Object.assign({ $limit: limit, $skip: page * limit }, queryOptions)
            }));
            _DEBUG && console.log("Subtracting from", this.isLoading[loading_key], loading_key, "page", page);
            _DEBUG && console.log("Data loaded", data.total);
            const newerLoadIdExists = this.isLoading[loading_key].some((element) => element > load_id);
            const lastLoadedIdIsNewer = this.lastLoaded[loading_key] > load_id;
            if ((newerLoadIdExists || lastLoadedIdIsNewer) && !page) {
                _DEBUG && console.log("Canceling outadated", loading_key);
                runInAction(() => {
                    const idx = this.isLoading[loading_key].indexOf(load_id);
                    this.isLoading[loading_key].splice(idx, 1);
                });
                return;
            }
            runInAction(() => {
                if (!page)
                    keys(container).forEach((k) => remove(container, k));
                Object.assign(container, (data.data.reduce((res, val) => {
                    callBack && callBack(val);
                    res[Number(val.id)] = conversion(val);
                    return res;
                }, {})));
                this.lastLoaded[loading_key] = load_id;
            });
            if ((page + 1) * limit < data.total)
                yield this.loadItems(queryOptions, service, container, loading_key, conversion, callBack, page + 1);
            else {
                runInAction(() => {
                    const idx = this.isLoading[loading_key].indexOf(load_id);
                    this.isLoading[loading_key].splice(idx, 1);
                });
                const later = new Date().getTime();
                _DEBUG && console.log("Loading", loading_key, "took", later - now, "ms");
            }
        });
        this.getOfficeByID = (id) => {
            return this.offices[id];
        };
        this.getVehicle = (id) => {
            return (this.vehicles && this.vehicles[id]) || (this.temp_vehicles && this.temp_vehicles[id]);
        };
        this.getTour = (id) => {
            return this.tours && this.tours[id];
        };
        this.getFilter = (filterGroup, key) => {
            var _a;
            return ((_a = this.rootStore.userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.getFilter(filterGroup, key)) || false;
        };
        this.getOfficeRow = computedFn((office_id) => {
            var _a;
            const dayList = this.getFilter(FilterGroup.Other, Filter.Weekend) ?
                ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] :
                ["mon", "tue", "wed", "thu", "fri"];
            const res = {
                name: ((_a = this.offices[office_id]) === null || _a === void 0 ? void 0 : _a.name) || "",
                mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [],
                id: office_id
            };
            if (this.isLoading.notes.length || this.isLoading.tours.length)
                return res;
            const filteredLabels = Object.values(TourLabel).filter(l => l && this.getFilter(FilterGroup.Label, l));
            let tours = Object.values(this.getToursByOffice(office_id) || {}).filter(t => {
                if (!t.label)
                    return true;
                return !filteredLabels.length || filteredLabels.includes(t.label);
            });
            if (this.getOwnToursFilter)
                tours = tours.filter(t => { var _a; return t.created_by === ((_a = this.rootStore.userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.id); });
            if (this.hideInvoicedFilter)
                tours = tours.filter(t => !t.invoiced);
            tours = tours.sort((lhs, rhs) => {
                const lhsTime = (lhs.start_time && format(lhs.start_time, "HH:mm")) || "";
                const rhsTime = (rhs.start_time && format(rhs.start_time, "HH:mm")) || "";
                return lhsTime > rhsTime ? 1 : (lhsTime < rhsTime ? -1 : 0);
            });
            tours.forEach(t => {
                if (!t || !t.date || !dayList[getISODay(t.date) - 1])
                    return;
                res[dayList[getISODay(t.date) - 1]].push(t);
            });
            return res;
        });
        this.getCalendarRows = computedFn((mobile) => {
            const hideNotes = (this.notesFilterMobile && mobile) || (this.notesFilterDesktop && !mobile);
            if (this.isLoadingCalendar)
                return DataStore.empty_list;
            return hideNotes
                ? [...Object.keys(this.getOffices).map(o => this.getOfficeRow(Number(o)))]
                : [this.getNotesRow, ...Object.keys(this.getOffices).map(o => this.getOfficeRow(Number(o)))];
        });
        this.getToursByOffice = computedFn((office_id) => {
            return this.tours_by_office[office_id];
        });
        this.setSearchString = (str) => {
            this.searchString = str;
        };
        this.setDate = (d) => {
            if (!d)
                return;
            this.curDate = d;
        };
        this.setActiveTour = (t) => {
            if (t) {
                this.activeTour = Tour.create(t);
                this.activeOffice = this.offices[t.office_id];
            }
            else {
                this.activeTour = null;
            }
        };
        this.setActiveNote = (n) => {
            if (n)
                this.activeNote = Note.create(n);
            else
                this.activeNote = null;
        };
        this.setActiveOffice = (g) => {
            this.activeOffice = g;
        };
        this.setActiveVehicle = (v) => {
            this.activeVehicle = v ? Vehicle.create(v) : null;
        };
        this.setActiveProp = (type, prop, val) => {
            switch (type) {
                case DataType.Note:
                    set(this.activeNote, prop, val);
                    break;
                case DataType.Tour:
                    set(this.activeTour, prop, val);
                    break;
                default:
                    break;
            }
        };
        this.setReplyComment = (comment) => {
            this.replyComment = comment;
        };
        this.setCommentFile = (file) => {
            this.commentFile = file;
        };
        this.toggleOwnToursFilter = (check) => {
            this.ownToursFilter = check;
        };
        this.toggleNotesFilterMobile = () => {
            this.notesFilterMobile = !this.notesFilterMobile;
        };
        this.toggleNotesFilterDesktop = () => {
            this.notesFilterDesktop = !this.notesFilterDesktop;
        };
        this.toggleHideInvoicedFilter = () => {
            this.hideInvoicedFilter = !this.hideInvoicedFilter;
        };
        this.filterOffices = (offices) => {
            if (!offices)
                return {};
            return Object.values(offices)
                .filter(o => o && this.getFilter(FilterGroup.Office, o.id))
                .reduce((res, val) => {
                res[Number(val.id)] = val;
                return res;
            }, {});
        };
        this.filterTours = (tours) => {
            if (!tours)
                return {};
            return Object.values(tours).filter(t => t &&
                (!t.label || this.getFilter(FilterGroup.Label, t.label)) &&
                this.getFilter(FilterGroup.Office, t.office_id) &&
                t.date && isSameWeek(t.date, this.curDate)).reduce((res, val) => {
                res[Number(val.id)] = val;
                return res;
            }, {});
        };
        this.filterNotes = (notes) => {
            if (!notes)
                return {};
            return Object.values(notes)
                .filter(n => n && (!n.office_id || this.getFilter(FilterGroup.Office, n.office_id)))
                .reduce((res, val) => {
                res[Number(val.id)] = val;
                return res;
            }, {});
        };
        this.searchTours = (searchString, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const searchBatchSize = 100;
            const filteredOffices = Object.keys(this.offices).filter(o => o && this.getFilter(FilterGroup.Office, o)).map(o => Number(o));
            const filteredLabels = Object.values(TourLabel).filter(l => l && this.getFilter(FilterGroup.Label, l));
            const params = {
                query: {
                    $limit: searchBatchSize,
                    $skip: page * searchBatchSize,
                    $and: [
                        { $or: [].concat(...searchString.split(',').map(s => {
                                const byProp = [
                                    { title: { $ilike: '%' + s + '%' } },
                                    { customer: { $ilike: '%' + s + '%' } },
                                ];
                                if (Boolean(Number(s)))
                                    byProp.push({ lime_id: Number(s) });
                                return byProp;
                            })) },
                        { office_id: { $in: filteredOffices } },
                        { $or: [
                                { label: { $in: filteredLabels } },
                                { label: null },
                            ] },
                    ]
                }
            };
            yield this.loadItems(params, this.tour_service, this.searchResultTours, "search_tour", Tour.create);
            runInAction(() => {
                this.searchResultToursByOffice = arrToObjAgg(Object.values(this.searchResultTours), "office_id");
            });
        });
        this.searchNotes = (searchString, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const searchBatchSize = 100;
            const filteredOffices = Object.keys(this.offices)
                .filter(o => o && this.getFilter(FilterGroup.Office, o))
                .map(o => Number(o));
            const params = {
                query: {
                    $limit: searchBatchSize,
                    $skip: page * searchBatchSize,
                    $and: [
                        {
                            $or: [].concat(...searchString.split(',').map(s => [
                                { desc: { $ilike: '%' + s + '%' } },
                                { title: { $ilike: '%' + s + '%' } },
                            ]))
                        },
                        { office_id: { $in: filteredOffices } }
                    ]
                }
            };
            yield this.loadItems(params, this.note_service, this.searchResultNotes, "search_notes", Note.create);
        });
        this.search = (searchString) => __awaiter(this, void 0, void 0, function* () {
            this.searchNotes(searchString);
            this.searchTours(searchString);
        });
        this.debounceSearch = () => {
            this.debounce(() => this.search(this.searchString), this.debounceDelay);
        };
        this.resetSearch = () => {
            keys(this.searchResultTours).forEach((k) => remove(this.searchResultTours, k));
            keys(this.searchResultNotes).forEach((k) => remove(this.searchResultNotes, k));
        };
        this.toggleFilter = (filterGroup, key) => {
            var _a;
            (_a = this.rootStore.userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.toggleFilter(filterGroup, key);
        };
        makeAutoObservable(this);
        this.rootStore = rootStore;
        when(() => this.rootStore.userStore.isLoggedIn, () => {
            this.loadItems({}, this.office_service, this.offices, "offices", Office.create);
            this.loadItems({
                date: {
                    $gte: startOfWeek(this.curDate),
                    $lte: endOfWeek(this.curDate),
                },
            }, this.tour_service, this.tours, "tours", Tour.create);
            this.loadItems({
                $or: [{
                        from: { $lte: endOfWeek(this.curDate) },
                        to: { $gte: startOfWeek(this.curDate) }
                    }, {
                        to: { $gte: startOfWeek(this.curDate) },
                        from: { $lte: endOfWeek(this.curDate) }
                    }]
            }, this.note_service, this.notes, "notes", Note.create);
            this.loadItems({ temp_date: null }, this.vehicle_service, this.vehicles, "vehicles", Vehicle.create);
            this.loadItems({
                date: {
                    $gte: startOfWeek(startOfDay(this.curDate)),
                    $lte: endOfWeek(endOfDay(this.curDate))
                }
            }, this.tour_users_service, this.tour_users, "tour_users", TourUser.create);
        });
        reaction(() => this.activeTour, () => {
            if (!this.activeTour) {
                keys(this.comments).forEach((k) => remove(this.comments, k));
                return;
            }
            this.loadItems({ item_id: this.activeTour.id, item_type: "tour" }, this.comment_service, this.comments, "comments", Comment.create);
        });
        reaction(() => this.curDate, () => {
            const _startOfWeek = startOfWeek(this.curDate);
            const _endOfWeek = endOfWeek(this.curDate);
            this.loadItems({
                date: {
                    $gte: _startOfWeek,
                    $lte: _endOfWeek,
                },
            }, this.tour_service, this.tours, "tours", Tour.create);
            this.loadItems({
                $or: [{
                        from: { $lte: _endOfWeek },
                        to: { $gte: _startOfWeek }
                    }, {
                        to: { $gte: _startOfWeek },
                        from: { $lte: _endOfWeek }
                    }]
            }, this.note_service, this.notes, "notes", Note.create);
            this.loadItems({
                temp_date: {
                    $gte: startOfDay(this.curDate),
                    $lt: endOfDay(this.curDate)
                }
            }, this.vehicle_service, this.temp_vehicles, "temp_vehicles", Vehicle.create);
            this.loadItems({
                date: {
                    $gte: _startOfWeek,
                    $lte: _endOfWeek
                }
            }, this.tour_users_service, this.tour_users, "tour_users", TourUser.create);
            this.rootStore.userStore.loadTempUsers();
        });
        reaction(() => this.getTours, () => {
            if (!this.tours)
                this.tours_by_office = {};
            this.tours_by_office = Object.values(this.tours)
                .reduce((res, val) => {
                res[val.office_id] = res[val.office_id] || {};
                res[val.office_id][val.id] = val;
                return res;
            }, {});
        });
        autorun(() => {
            if (this.searchString.length >= 3)
                this.debounceSearch();
            else
                this.resetSearch();
        });
        this.registerEventListener(this.tour_service, this.tours, Tour.create, null, true);
        this.registerEventListener(this.note_service, this.notes, Note.create);
        this.registerEventListener(this.comment_service, this.comments, Comment.create);
        this.registerEventListener(this.vehicle_service, this.vehicles, Vehicle.create, this.temp_vehicles);
        this.registerEventListener(this.tour_users_service, this.tour_users, TourUser.create);
        this.comment_service.on("created", (data) => runInAction(() => {
            var _a;
            if (data.item_type === "tour" && this.tours[data.item_id]) {
                this.tours[data.item_id].num_comments++;
                if (Number(data.item_id) === ((_a = this.activeTour) === null || _a === void 0 ? void 0 : _a.id)) {
                    this.activeTour.num_comments++;
                }
            }
        }));
        this.comment_service.on("removed", (data) => runInAction(() => {
            var _a;
            if (data.item_type === "tour" && this.tours[data.item_id]) {
                this.tours[data.item_id].num_comments--;
                if (Number(data.item_id) === ((_a = this.activeTour) === null || _a === void 0 ? void 0 : _a.id))
                    this.activeTour.num_comments--;
            }
        }));
        this.initWebSettings();
    }
    get getSearchString() {
        return this.searchString;
    }
    get getReplyComment() {
        return this.replyComment;
    }
    get getCommentFile() {
        return this.commentFile;
    }
    get hasChangedTour() {
        return !!(this.activeTour && (this.activeTour.isNew || this.activeTour.hasChanged));
    }
    get getOffices() {
        return this.filterOffices(this.offices);
    }
    get getOfficesUnfiltered() {
        return this.offices;
    }
    get getCurTours() {
        return this.filterTours(this.tours) || {};
    }
    get getCurNotes() {
        return this.filterNotes(this.notes) || {};
    }
    get getActiveOffice() {
        return this.activeOffice;
    }
    get getDefaultOffice() {
        return Object.values(this.offices)[0];
    }
    get getActiveTour() {
        return this.activeTour;
    }
    get getActiveVehicle() {
        return this.activeVehicle;
    }
    get getActiveNote() {
        return this.activeNote;
    }
    get getCurDate() {
        return this.curDate;
    }
    get getSchedulerTours() {
        if (!this.activeOffice || !this.curDate)
            return [];
        return Object.values(this.tours).filter(t => {
            var _a;
            return t && t.office_id === ((_a = this.activeOffice) === null || _a === void 0 ? void 0 : _a.id) && t.date && isSameDay(this.curDate, t.date);
        }).sort((lhs, rhs) => {
            const lhsStartTime = (lhs.start_time && format(lhs.start_time, "HH:mm")) || "";
            const rhsStartTime = (rhs.start_time && format(rhs.start_time, "HH:mm")) || "";
            const lhsEndTime = (lhs.end_time && format(lhs.end_time, "HH:mm")) || "";
            const rhsEndTime = (rhs.end_time && format(rhs.end_time, "HH:mm")) || "";
            return lhsStartTime > rhsStartTime ? 1 : (lhsStartTime < rhsStartTime ? -1 : (lhsEndTime > rhsEndTime ? 1 : (lhsEndTime < rhsEndTime ? -1 : 0)));
        });
    }
    get getTourCounts() {
        if (!this.getActiveOffice)
            return { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 };
        return Object.values(this.tours).reduce((res, val) => {
            var _a;
            if (!!val && val.office_id === ((_a = this.getActiveOffice) === null || _a === void 0 ? void 0 : _a.id) && val.date)
                res[getDay(val.date)]++;
            return res;
        }, { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 });
    }
    get getTourCompletionStatus() {
        if (!this.getActiveOffice)
            return { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false };
        const counts = this.getTourCounts;
        return Object.values(this.tours)
            .filter(t => { var _a; return t && t.office_id === ((_a = this.getActiveOffice) === null || _a === void 0 ? void 0 : _a.id); })
            .reduce((res, t) => {
            if (t.date)
                res[getDay(t.date)] = res[getDay(t.date)] && t.isComplete;
            return res;
        }, Object.assign({}, [1, 2, 3, 4, 5, 6, 7].map(d => Boolean(counts[d]))));
    }
    get getSchedulerVehicles() {
        if (!this.activeOffice)
            return [];
        return Object.values(Object.assign(Object.assign({}, this.vehicles), this.temp_vehicles)).filter(v => { var _a; return v && v.office_id === ((_a = this.activeOffice) === null || _a === void 0 ? void 0 : _a.id); });
    }
    get getVehicles() {
        return this.vehicles;
    }
    get getTours() {
        return this.tours ? Object.values(this.tours) : [];
    }
    get getComments() {
        return this.comments;
    }
    get getTourSearchResult() {
        return this.searchResultTours;
    }
    get getNoteSearchResult() {
        return this.searchResultNotes;
    }
    get getMentionOptions() {
        var _a, _b;
        return Object.values(this.vehicles)
            .map(v => (v.name || "").toLowerCase()).concat((_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.userStore) === null || _b === void 0 ? void 0 : _b.getUsers.map(u => u.first_name || '')).filter(s => Boolean(s));
    }
    get getOwnToursFilter() {
        return this.ownToursFilter;
    }
    get getNotesFilter() {
        return this.notesFilterMobile;
    }
    get getNotesRow() {
        const dayList = this.getFilter(FilterGroup.Other, Filter.Weekend) ?
            ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] :
            ["mon", "tue", "wed", "thu", "fri"];
        const res = {
            name: i18next.t("note_plural"),
            mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [],
            id: 0
        };
        if (this.isLoading.notes.length || this.isLoading.tours.length)
            return res;
        const notes = Object.values(Boolean(this.searchString) ? this.getNoteSearchResult : this.getNotesFilter ? this.getCurNotes : this.getCurNotes);
        const weekStart = startOfWeek(this.getCurDate);
        const weekList = dayList.map((val, idx) => add(weekStart, { days: idx }));
        notes.forEach(n => {
            if (!n || !n.from || !n.to)
                return;
            const from_day = format(n.from, "EEE").toLowerCase();
            const to_day = format(n.to, "EEE").toLowerCase();
            weekList.forEach((val, idx) => {
                if (n.from && n.to && startOfDay(val) >= startOfDay(n.from) && startOfDay(val) <= startOfDay(n.to)) {
                    res[dayList[idx]].push(n);
                }
            });
        });
        return res;
    }
    get isLoadingCalendar() {
        return Boolean(this.isLoading.tours.length || this.isLoading.offices.length || this.isLoading.notes.length);
    }
    saveWebSetting(key, setting) {
        let current = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('_sbk_web_settings')) ? JSON.parse(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('_sbk_web_settings')) : this.webSettings;
        current[key] = setting;
        localStorage.setItem('_sbk_web_settings', JSON.stringify(current));
    }
    initWebSettings() {
        const settings = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('_sbk_web_settings')) ? JSON.parse(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('_sbk_web_settings')) : {};
        if (!settings)
            return;
        this.webSettings = { activeTourFilters: settings.activeTourFilters };
    }
    debounce(fn, delay) {
        if (this.debounceTimer)
            clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            fn();
            runInAction(() => {
                this.debounceTimer = null;
            });
        }, delay);
    }
}
DataStore.empty_list = [];
