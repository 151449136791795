import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Note, Tour } from '@/models';
import { AppRoute } from '@/routes';
import { toCap } from '@/util';
import { LoadingView } from '@/views';
import { add, format, isSameDay, startOfWeek } from 'date-fns';
import { de, enUS, sv } from 'date-fns/esm/locale';
import en from 'date-fns/locale/en-US';
import { Box, Button, ResponsiveContext, Text, TextInput } from 'grommet';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalendarDateSelection } from './calendarDateSelection';
import { DataCell } from './dataCell';
import { MobileFilterModal } from './mobileFilterModal';
import { FormSearch } from 'grommet-icons';
import { Cancel01Icon } from 'hugeicons-react';
import { SearchView } from './searchView';
export const debounce = (clbk, debounceDelay = 500) => {
    let debounceTimer = null;
    return (args) => {
        debounceTimer && clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => clbk(args), debounceDelay);
    };
};
export const MobileCalendar = inject("dataStore", "userStore")(observer((props) => {
    var _a;
    const { dataStore, userStore } = props;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dateLocales = { en: enUS, sv: sv, de: de };
    const size = React.useContext(ResponsiveContext);
    const [openFilters, setOpenFilters] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(userStore.getDefaultOfficeId);
    const [searchString, setSearchString] = React.useState('');
    const [isSearching, setIsSearching] = React.useState(false);
    const handleSearchChange = (event) => {
        const newSearchString = event.target.value;
        setSearchString(newSearchString);
        setIsSearching(newSearchString.length > 0);
    };
    const _applyQuery = React.useCallback(debounce(handleSearchChange), [searchString]);
    const clearSearch = () => {
        setSearchString("");
        setIsSearching(false);
    };
    const curDay = [format((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date(), 'EEE', { locale: en }).toLowerCase()];
    const columnHeaders = React.useMemo(() => {
        var _a, _b, _c;
        const elements = [
            _jsx(Box, Object.assign({ pad: "small", background: "light-1" }, { children: _jsx(Text, Object.assign({ weight: "bold" }, { children: t("office") })) }), "office_column"),
        ];
        const dayOfWeek = {
            "mon": 0,
            "tue": 1,
            "wed": 2,
            "thu": 3,
            "fri": 4,
            "sat": 5,
            "sun": 6
        };
        return elements.concat(_jsxs(Box, Object.assign({ hoverIndicator: true, justify: "between", pad: "small", direction: "row", background: "light-1", onClick: () => {
                if (!Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString)) {
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(Object.values(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered)[0]);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(add(startOfWeek(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate), {
                        days: dayOfWeek[curDay[0]],
                    }));
                    navigate(AppRoute.Scheduler);
                }
            } }, { children: [_jsx(Text, Object.assign({ weight: "bold", className: !Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) &&
                        isSameDay(new Date(), add(startOfWeek((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date()), {
                            days: dayOfWeek[curDay[0]],
                        }))
                        ? "current-date"
                        : undefined }, { children: !Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) &&
                        format(add(startOfWeek((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _b !== void 0 ? _b : new Date()), {
                            days: dayOfWeek[curDay[0]],
                        }), "d") }), "date"), _jsx(Text, Object.assign({ weight: "bold", color: "dark-5" }, { children: toCap(format(add(startOfWeek((_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _c !== void 0 ? _c : new Date()), {
                        days: dayOfWeek[curDay[0]],
                    }), "E", { locale: dateLocales[i18n.language] })) }), "day")] }), "day_" + dayOfWeek[curDay[0]]));
    }, [
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
        Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString),
        i18n.language,
    ]);
    const rows = dataStore.getCalendarRows(true);
    const isLoading = React.useDeferredValue(dataStore.isLoadingCalendar);
    return (_jsxs(Box, Object.assign({ fill: true, overflow: "hidden", className: "rc-container", pad: "medium" }, { children: [_jsx(Box, { children: _jsxs(Box, Object.assign({ className: "rc-header", direction: size === "small" ? "column" : "row", align: "center", gap: "medium", margin: { vertical: "medium" } }, { children: [_jsx(CalendarDateSelection, { dayMode: true }), _jsxs(Box, Object.assign({ direction: "row", gap: "medium", justify: "between", width: "100%" }, { children: [((userStore === null || userStore === void 0 ? void 0 : userStore.isAdmin) || (userStore === null || userStore === void 0 ? void 0 : userStore.isSales)) && (_jsx(Button, { primary: true, style: { padding: "12px 12px" }, className: "styled-button", label: t("create_new_tour"), color: "accent-1", icon: _jsx(_Fragment, {}), onClick: () => {
                                        const newTour = Tour.create({
                                            date: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                            office_id: userStore.getDefaultOfficeId,
                                        });
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(newTour);
                                    } })), _jsx(Button, { primary: true, style: { padding: "12px 12px" }, label: t("create_note"), className: "styled-button", color: "accent-1", icon: _jsx(_Fragment, {}), onClick: () => {
                                        const newNote = Note.create({
                                            from: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                            to: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                            office_id: userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOfficeId,
                                        });
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(newNote);
                                    } })] })), _jsxs(Box, Object.assign({ align: "center", width: "100%", direction: 'row', gap: "medium" }, { children: [_jsxs(Box, Object.assign({ width: "50%" }, { children: [_jsx(Button, { secondary: true, className: "styled-button", label: t("filter"), color: "black", icon: _jsx(_Fragment, {}), onClick: () => setOpenFilters(true) }), openFilters &&
                                            _jsx(MobileFilterModal, { onClose: () => setOpenFilters(false) })] })), _jsxs(Box, Object.assign({ className: 'search-bar', direction: "row", align: "center", width: "50%", background: "white", border: { color: "light-4", size: "xsmall", style: "solid" }, round: "small", pad: { horizontal: "small" } }, { children: [_jsx(TextInput, { className: 'search-bar-input', icon: _jsx(FormSearch, { height: 18, width: 18 }), placeholder: t("search_bar_placeholder"), defaultValue: searchString, onChange: _applyQuery, plain: true }), searchString && (_jsx(Button, { className: 'search-bar-clear', icon: _jsx(Cancel01Icon, { height: 18, width: 18, className: 'search-bar-clear' }), onClick: clearSearch, plain: true, hoverIndicator: true }))] }))] }))] })) }), isSearching ? (_jsx(SearchView, { searchString: searchString, setSearchString: setSearchString, dataStore: dataStore, userStore: userStore })) : isLoading ? (_jsx(LoadingView, {})) : (_jsx(Box, Object.assign({ flex: "shrink", background: "white" }, { children: _jsxs("div", Object.assign({ className: "mobileCalendar" }, { children: [_jsx("div", Object.assign({ className: "mobileCalendar-header" }, { children: columnHeaders }), "header"), rows.map((row) => {
                            const cells = [
                                _jsx(Box, Object.assign({ hoverIndicator: true, pad: "small", onClick: () => setSelectedRow(row.id === selectedRow ? -1 : row.id) }, { children: _jsx(Text, Object.assign({ size: "small" }, { children: row.name })) }), row.id),
                            ];
                            return (_jsx("div", Object.assign({ className: "mobileCalendar-row", style: { minHeight: "100px" } }, { children: cells.concat(curDay.map((day, idx) => {
                                    var _a;
                                    return (_jsx(DataCell, { noDnD: true, tours: row.id ? row[day] : undefined, office_id: row.id, notes: row.id === 0 ? row[day] : undefined, elKey: "cell" + row.id + day, date: add(startOfWeek((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date()), { days: idx }) }, "warppercell" + row.id + day));
                                })) }), "row" + row.id));
                        })] }), "mobileCalendar") })))] })));
}));
